<template>
  <div class="header">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <transition-group name="breadcrumb">
          <el-breadcrumb-item
            v-for="(bread, index) in breadcrumb"
            :key="bread.path"
          >
            <a
              v-if="isRedirectable(bread, index)"
              @click.prevent="handleLink(bread)"
              >{{ bread.meta.title }}
            </a>
            <span v-else class="no-redirect">
              {{ bread.meta.title }}
            </span>
          </el-breadcrumb-item>
        </transition-group>
      </el-breadcrumb>
    </div>
    <div class="flex-auto"></div>
    <div class="teanName">{{ teantName }}</div>
    <div v-if="buttonDisable" style="margin-right: 3px">
      <el-button @click="backShangHu" type="primary" icon="el-icon-switch-button" size="small">返回商户</el-button>
    </div>
    <div class="pages">
      <el-select
        v-model="currentPageKey"
        size="small"
        placeholder="切换子系统"
        @change="changeSystem"
      >
        <el-option
          v-for="item in pages"
          :key="item.key"
          :label="item.title"
          :value="item.key"
        ></el-option>
      </el-select>
    </div>
    <div class="search">
      <el-autocomplete
        ref="functionSearcher"
        v-model="inputValue"
        :fetch-suggestions="searchFunction"
        @select="handleSelect"
        highlight-first-item
        :popper-class="$style['search-body']"
        placeholder="搜索功能"
        size="small"
      >
        <i slot="suffix" class="el-icon-search"></i>
        <template slot-scope="{ item }">
          <div v-html="item.value" class="search-line"></div>
        </template>
      </el-autocomplete>
    </div>

    <qr-code/>
    <header-notice class="notice" />

    <div class="user">
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          {{ name }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          <el-dropdown-item command="plugin">
            <a
              href="/Integratedplugin/Integratedplugin.exe"
              download="Integratedplugin.exe"
              >插件下载</a
            >
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <change-password
        @modified="changePassword"
        v-if="changePasswordVisible"
        :visible.sync="changePasswordVisible"
      ></change-password>
    </div>
  </div>
</template>

<script>
import HeaderNotice from "./HeaderNotice";
import RouterDispatcher from "./components/RouteDispatcher";
import { currentPageHref } from "@/pages/pages";
import { mapGetters, mapState } from "vuex";
import { flatten } from "min-dash";
import { getUserById } from "@/service/system/user";
import {getCurrentMenDianXXFromRedis} from "@/service/mdgl/MenDianXX"
import ChangePassword from "./ChangePassword";
import { deepCopy } from "@/util/objects";
import { LOCAL_STORAGE } from "@/util/storage";
import QrCode from './components/QrCode'
import {clearMenDianXXFromRedis} from "@/service/mdgl/MenDianXX"

export default {
  name: "Header",
  mixins: [RouterDispatcher],
  components: {
    HeaderNotice,
    ChangePassword,
    QrCode
  },
  data() {
    return {
      currentPageKey: currentPageHref,
      inputValue: "",
      breadcrumb: [],
      pages: deepCopy(this.$store.getters.subsystemRoutes),
      changePasswordVisible: false,
      teantName: LOCAL_STORAGE.get("initTenantName"),
      buttonDisable:false
    };
  },
  computed: {
    ...mapGetters({ routes: "permission_routes", user: "user" }),
    name() {
      return this.$store.getters.user.name;
    },
  },
  created() {
    this.refreshBreadcrumb();
  },
  mounted() {
    const functionSearcherRef = this.$refs.functionSearcher;
    functionSearcherRef.$watch("highlightedIndex", (newVal, oldVal) => {
      if (
        newVal === -1 &&
        oldVal === 0 &&
        functionSearcherRef.suggestions.length
      ) {
        functionSearcherRef.highlight(
          functionSearcherRef.suggestions.length - 1
        );
      }
    });
    getUserById(this.user.id).then((response) => {
      let { data } = response;
      this.$store.commit("user/SET_USER_INFO", data);
    });
    this.pages.push({ key: "/", title: "导航页" });
    let shangHu = LOCAL_STORAGE.get("shangHu")
    if(shangHu){
      this.buttonDisable = true
    }
    let menDianName = LOCAL_STORAGE.get("menDianName")
    if(menDianName){
      this.teantName = this.teantName+"-"+menDianName
    }else{
      getCurrentMenDianXXFromRedis().then((response) => {
        if (response.data) {
          LOCAL_STORAGE.set("menDianId", response.data.id);
          LOCAL_STORAGE.set("menDianName", response.data.menDianMC);
          this.teantName = this.teantName+"-"+response.data.menDianMC
        }
      })
    }
  },
  watch: {
    $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.refreshBreadcrumb();
    },
  },
  methods: {
    // showQrCode(){
    //     this.visible = true;
    //     this.qrcode();
    // },
    // //关闭弹框清除二维码
    // qrCodeHandleClose() {
    //   this.visible = false;
    //   this.qrCode = "";
    //   document.getElementById("qrCode").innerHTML = "";
    // },
    // //创建二维码
    // qrcode() {
    //   let _text = `tenantKey：${getTenantKey()}\n BASE_API：${bjggs.BASE_API}`
    //   this.$nextTick(() => {
    //     this.qrCode = new QRCode("qrCode", {
    //       text: _text,
    //       width: 160,
    //       height: 160,
    //     });
    //   });
    // },
    async handleCommand(command) {
      if (command === "logout") {
        //清除门店相关信息
        LOCAL_STORAGE.remove("menDianId");
        LOCAL_STORAGE.remove("menDianName");
        LOCAL_STORAGE.remove("shangHu")
        this.buttonDisable = false
        await this.$store.dispatch("user/logout");
        window.location.reload(true);
      }
      if (command === "changePassword") {
        this.changePasswordVisible = true;
      }
      // if (command === "plugin") {
      //     this.$confirm('请点击确认下载插件?', '提示', {
      //         confirmButtonText: '确定',
      //         cancelButtonText: '取消',
      //         type: 'warning'
      //     }).then(() => {
      //         window.open('/Integratedplugin/Integratedplugin.exe');
      //     }).catch(() => {
      //         this.$message({
      //             type: 'info',
      //             message: '已取消下载'
      //         });
      //     });
      // }
    },
    searchFunction(queryString, cb) {
      if ((this.inputValue = queryString)) {
        const routes = this.routes;
        const matched = flatten(
          routes.map((item) => this.findMatchedFunction(item))
        );
        cb(matched);
      } else {
        cb([]);
      }
    },
    //返回商户
    backShangHu(){
      //清除门店相关信息
      LOCAL_STORAGE.remove("menDianId");
      LOCAL_STORAGE.remove("menDianName");
      LOCAL_STORAGE.remove("shangHu")
      clearMenDianXXFromRedis().then((response)=>{
      })
      this.buttonDisable = false
      window.location.href = "/";
    },

    handleSelect(selected) {
      this.toSelected(selected);
      this.inputValue = null;
    },
    findMatchedFunction: function (
      route,
      routePaths = [],
      routeTitles = [],
      depth = 0
    ) {
      let { meta: { title, searchable } = {}, children, path } = route;
      const keyword = this.inputValue;
      let matchedChildren = [];

      const upgrade = () => {
        routePaths[depth] = path;
        routeTitles[depth] = this.highlightKeyword(title, keyword);
        depth = depth + 1;
      };
      if (searchable && title.indexOf(keyword) > -1) {
        if (children && children.length) {
          upgrade();
          matchedChildren = flatten(
            children.map((item) =>
              this.findTagsWithChildren(item, routePaths, routeTitles, depth)
            )
          );
        } else {
          matchedChildren = this.findTagsWithChildren(
            route,
            routePaths,
            routeTitles,
            depth
          );
        }
      } else {
        if (children && children.length) {
          upgrade();
          matchedChildren = flatten(
            children.map((item) =>
              this.findMatchedFunction(item, routePaths, routeTitles, depth)
            )
          );
        }
      }
      return matchedChildren;
    },
    findTagsWithChildren(route, routePaths = [], routeTitles = [], depth) {
      let { children, path, meta: { title } = {} } = route;
      routePaths[depth] = path;
      routeTitles[depth] = this.highlightKeyword(title, this.inputValue);

      let matchedChildren = [];
      if (children && children.length) {
        matchedChildren = flatten(
          children.map((item) =>
            this.findTagsWithChildren(item, routePaths, routeTitles, depth + 1)
          )
        );
      } else {
        let fullPath = routePaths.join("/");
        let fullTitle = routeTitles.filter((item) => item).join(" > ");
        matchedChildren.push({ path: fullPath, value: fullTitle });
      }
      return matchedChildren;
    },
    highlightKeyword(title, keyword) {
      return (
        title &&
        title.replace(keyword, `<span class='search-item'>${keyword}</span>`)
      );
    },
    toSelected(route) {
      this.goto(route);
    },
    refreshBreadcrumb() {
      //只显示带title的component
      const breadcrumb = [];
      const ancestors = [...this.getAncestors(this.$route), this.$route];
      const displayableAncestors = ancestors.filter(
        ({ meta }) => meta.title && meta.breadcrumb !== false
      );

      if (!this.isDashboard(displayableAncestors[0])) {
        //所有子系统首页组件的path、name及title都固定为以下值
        breadcrumb.push({
          name: "Dashboard",
          path: "/dashboard",
          meta: { title: "首页" },
        });
      }

      this.breadcrumb = [...breadcrumb, ...displayableAncestors];
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return (
        name.trim().toLocaleLowerCase() === "Dashboard".toLocaleLowerCase()
      );
    },
    getAncestors(route) {
      let ancestors = [];
      for (
        let parent = route.meta && route.meta.parent;
        !!parent;
        parent = parent.meta && parent.meta.parent
      ) {
        ancestors.push(parent);
      }
      return ancestors.reverse();
    },
    handleLink(route) {
      //TODO 通过redirect到默认子组件时，可能会丢失已打开的tab其路由上的参数，暂无较好的处理方法，详情见RouteDispatcher组件 by hbh
      const { meta, redirect, path } = route;
      //如果是menuOnly的route则拼接路径跳转，见/src/router/helper.findFlattedRedirectOnlyRoutes
      if (meta && meta.menuOnly && redirect && redirect !== "noRedirect") {
        const ancestors = this.getAncestors(route);
        const prefix = ancestors
          .map((ancestor) => (ancestor && ancestor.path) || "")
          .join("/");
        this.goto({ path: `${prefix}/${path}` });
      } else {
        this.goto(route);
      }
    },
    isRedirectable(route, indexOf) {
      return (
        route.redirect !== "noRedirect" &&
        indexOf !== this.breadcrumb.length - 1
      );
    },
    changeSystem(val) {
      if (val) {
        window.location.href = val;
      }
    },
    async changePassword() {
      this.changePasswordVisible = false;
      await this.$store.dispatch("user/logout");
      window.location.reload(true);
    },
  },
};
</script>

<style module>
@import url("https://at.alicdn.com/t/font_2031379_vtjw5jo91zl.css");

.search-body {
  min-height: 120px;
  width: auto !important;
  min-width: 200px;
}
</style>
