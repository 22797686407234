import SockJS from "sockjs-client";
import Stomp from "stompjs";
import AbstractXHRObject from 'sockjs-client/lib/transport/browser/abstract-xhr';
import {mapGetters, mapState} from 'vuex';
import {doNothing} from '@/util/objects';
import {getTenantKey} from '@/util/auth'


//禁用SockJs的withCredentials
const _start = AbstractXHRObject.prototype._start;
AbstractXHRObject.prototype._start = function (method, url, payload, opts) {
    if (!opts) {
        opts = {noCredentials: true};
    }
    return _start.call(this, method, url, payload, opts);
};

const individualPrefix = "/user";
const applicationPrefix = "/app";

let stompClient;
let instanceCount = 0;
let webSocketConnectingPromise;

export default {
    name: "WebSocketSupporter",
    computed: {
        ...mapGetters(["token"]),
        ...mapState({userId: state => state.user.user.id})
    },
    created() {
        if (!instanceCount++) {
            const sockJs = new SockJS(`${this.$bjggs.WS_API}?Authorization=Bearer ${this.token}&tenantKey=${getTenantKey()}`);
            this.stompClient = stompClient = Stomp.over(sockJs);

            //set heartbeat interval
            stompClient.heartbeat.outgoing = 20000; // client will send heartbeats every 20000ms
            stompClient.heartbeat.incoming = 0;     // client does not want to receive heartbeats from the server

            webSocketConnectingPromise = new Promise((resolve, reject) => {
                const connectCallback = (message) => {
                    resolve(message);
                    console.debug('WebSocket connect successful')
                };
                const errorCallback = (error) => {
                    reject(error);
                    console.error('WebSocket connect failed', error);
                };

                const header = {
                    userId: this.userId,
                    tenantKey: getTenantKey()
                };
                stompClient.connect(header, connectCallback, errorCallback);
            });
            //生产环境时关闭console中的debug信息
            if (process.env.NODE_ENV === 'production') {
                stompClient.debug = doNothing;
            }
        }
        this.unsubscribers = [];
    },
    destroyed() {
        //取消当前组件的所有订阅
        if (this.unsubscribers.length) {
            this.unsubscribers.forEach(unsubscriber => unsubscriber && unsubscriber.unsubscribe());
        }
        //没有已存在的实例，关闭WebSocket连接
        if (!instanceCount || !--instanceCount) {
            stompClient.disconnect();
        }
    },
    methods: {
        send(destination, headers = {}, message) {
            let determinedPath = destination.startsWith(applicationPrefix) ? destination : `${applicationPrefix}${destination}`;
            stompClient.send(determinedPath, headers, message);
        },
        subscribe(destination, handler, individual = false, headers = {}) {
            destination = destination.startsWith('/') ? destination : `/${destination}`;
            let determinedPath = individual ? `${individualPrefix}${destination}` : destination;

            const subscriber = () => {
                const unsubscribe = stompClient.subscribe(determinedPath, handler, headers);
                this.unsubscribers.push(unsubscribe);
            };
            webSocketConnectingPromise.then(subscriber);
        }
    }
}
