<template>
    <el-popover trigger="hover" placement="bottom" width="740" style="height:525px;overflow: auto;" v-model="visible">
        <template>
            <el-tabs v-model="activeName">
                <el-tab-pane :label="auditTitle" name="audit">
                    <el-table border height="400" :data="audit.records">
                        <el-table-column show-overflow-tooltip prop="title" label="标题"/>
                        <el-table-column show-overflow-tooltip width="120" prop="modular" label="模块"/>
                        <el-table-column width="120" prop="applicant" label="申请人"/>
                        <el-table-column width="120" prop="applyDate" label="申请时间"/>
                        <el-table-column width="90" align="center" label="操作">
                            <el-button slot-scope="{row}" type="success" size="mini" round @click="showAudit(row)">审核</el-button>
                        </el-table-column>
                    </el-table>
                    <div style="margin-top: 5px;text-align: center">
                        <el-button @click="toAudits" type="primary" size="small">查看更多</el-button>
                        <el-button @click="visible = !visible" type="danger" size="small">关闭</el-button>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="remindTitle" name="remind">
                    <el-table border height="400" :data="remind.records">
                        <el-table-column show-overflow-tooltip prop="title" label="标题"/>
                        <el-table-column show-overflow-tooltip width="120" prop="modular" label="模块"/>
                        <el-table-column width="120" prop="remindTargetDate" label="到期时间"/>
                        <el-table-column width="120" prop="remindDate" label="提醒时间"/>
                        <el-table-column width="90" align="center" label="操作">
                            <el-button slot-scope="{row}" type="success" size="mini" round @click="showRemind(row)">处理</el-button>
                        </el-table-column>
                    </el-table>
                    <div style="margin-top: 5px;text-align: center">
                        <el-button @click="toReminds" type="primary" size="small">查看更多</el-button>
                        <el-button @click="visible = !visible" type="danger" size="small">关闭</el-button>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="noticeTitle" name="notice">
                    <el-table border height="400" :data="notice.records">
                        <el-table-column show-overflow-tooltip prop="title" label="标题"/>
                        <el-table-column width="180" prop="createTime" label="通知时间"/>
                        <el-table-column width="200" align="center" label="操作">
                            <template slot-scope="{row}">
                                <el-button type="success" size="mini" round @click="showNotice(row)">查看</el-button>
                                <el-button type="info" size="mini" round @click="markRead(row)">标记为已读</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="margin-top: 5px;text-align: center">
                        <el-button @click="visible = !visible" type="danger" size="small">关闭</el-button>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </template>
        <span slot="reference" class="header-notice el-icon-bell" @click="visible = !visible">
            <el-badge :value="totalCount" :max="99">
                <el-icon style="font-size: 18px; padding: 4px" type="bell"/>
            </el-badge>
        </span>
        <component :is="audit.componentName" :audit="audit" @submitted="reload">
        </component>
        <component :is="remind.componentName" :remind="remind" @submitted="reload">
        </component>
        <component :is="notice.componentName" :notice="notice" @submitted="reload">
        </component>
    </el-popover>
</template>

<script>
    import {auditMainList} from "@/service/system/audit";
    import {remindMainList} from "@/service/system/remind";
    import {handleRead} from "@/service/system/notification";
    import {remove} from '@/util/objects'
    import WebSocketSupporter from "@/components/WebScoket/WebSocketSupporter";
    import Vue from "vue";

    export default {
        name: "HeaderNotice",
        mixins: [WebSocketSupporter],
        data() {
            return {
                visible: false,
                audit: {
                    componentName: "div",
                    id: null,
                    visible: false,
                    count: 0,
                    records: [],
                },
                remind: {
                    componentName: "div",
                    id: null,
                    visible: false,
                    count: 0,
                    records: [],
                },
                notice: {
                    componentName: "div",
                    id: null,
                    visible: false,
                    count: 0,
                    records: [],
                },
                activeName: 'audit'
            };
        },
        computed: {
            totalCount() {
                return this.audit.count + this.remind.count + this.notice.count;
            },
            auditTitle() {
                return `审核 [${this.audit.count}]`
            },
            remindTitle() {
                return `提醒 [${this.remind.count}]`
            },
            noticeTitle() {
                return `通知 [${this.notice.count}]`
            }
        },
        mounted() {
            this.reload();

            //订阅审核通知
            this.subscribe('/topic/audit', this.reload);


            //订阅提醒通知
            this.subscribe('/topic/remind', this.reload);

            //初始化订阅，接收已存在的全部未读通知
            this.subscribe('/app/notifications', this.receiveNotice);

            //订阅增量通知
            this.subscribe('/topic/notifications', this.receiveNotice, true);
        },
        methods: {
            reload() {
                this.getAudit();
                this.getRemind();
            },
            getAudit() {
                // 获取审核代办消息
                auditMainList().then((res) => {
                    let {records, total} = res.data;
                    this.audit.count = total || 0;
                    this.audit.records = records;
                })
            },
            getRemind() {
                remindMainList().then((res) => {
                    let {records, total} = res.data;
                    this.remind.count = total || 0;
                    this.remind.records = records;
                })
            },
            receiveNotice(message) {
                const notifications = JSON.parse(message.body);
                const notice = this.notice;
                notice.visible = true;

                if (Array.isArray(notifications)) {
                    notice.records = [...notifications, ...notice.records];
                } else {
                    notice.records.unshift(notifications);
                }
                notice.count = notice.records.length;
                if (notice.count) {
                    this.visible = true;
                    this.activeName = 'notice';
                }
            },
            showAudit(audit) {
                this.audit.id = audit.id;
                this.audit.visible = true;
                Vue.component(audit.view, (resolve) => require([`@/view/audit/${audit.view}`], resolve));
                this.audit.componentName = audit.view;
            },
            showRemind(remind) {
                this.remind.id = remind.id;
                this.remind.visible = true;
                Vue.component(remind.view, (resolve) => require([`@/view/audit/${remind.view}`], resolve));
                this.remind.componentName = remind.view;
            },
            showNotice(notice) {
                this.$msgbox({
                    title: notice.title,
                    message: this.createNoticeContentElement(notice.reference, notice.content),
                    confirmButtonText: '确定',
                    callback: action => action === 'confirm' && this.markRead(notice)
                });
            },
            createNoticeContentElement(reference, content) {
                if (reference) {
                    return <el-link type="primary" href={reference}>{content}</el-link>
                }
                return <span>{content}</span>
            },
            markRead(notice) {
                handleRead(notice.id).then(response => {
                    remove(this.notice.records, notice);
                    this.notice.count = this.notice.records.length;
                });
            },
            toAudits() {
                this.$router.push({
                    path: '/audit'
                });
            },
            toReminds() {
                this.$router.push({
                    path: '/remind'
                });
            }
        }
    };
</script>
<style lang="scss" scoped>
    .header-notice {
        display: inline-block;
        transition: all 0.3s;
        width: 45px;
        height: 25px;
        margin-left: 15px;
        cursor: pointer;
    }
</style>
