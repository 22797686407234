<template>
    <el-dialog :visible="visible" append-to-body destroy-on-close center
               @update:visible="$emit('update:visible', $event)"
               width="30%" title="修改密码" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules" label-width="90px" style="margin-top: 30px">
            <el-form-item label="旧密码" prop="oldPassword">
                <el-input v-model="form.oldPassword" maxlength="20" type="password" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
                <el-input v-model="form.newPassword" placeholder="请输入新密码" show-password maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="密码确认" prop="confirmNewPassword">
                <el-input v-model="form.confirmNewPassword" placeholder="请再次输入新密码" show-password maxlength="20" show-word-limit></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="confirmModification" :loading="modifying" round size="small">确认</el-button>
            <el-button type="primary" plain @click="$emit('update:visible', false)" round size="small">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {ruleBuilder} from '@/util/validate'
    import {changePassword} from '@/service/system/user';
    import  MD5  from 'js-md5';

    export default {
        name: "ChangePassword",
        props: {
            visible: Boolean,
        },
        data() {
            const {required, range, password} = ruleBuilder;

            const validatePassword = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                } else if (value === this.form.oldPassword) {
                  callback(new Error('新密码不能和旧密码一致!'));
                } else {
                    callback();
                }
            };
            const validateConfirmPass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.form.newPassword) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };

            this.rules = {
                oldPassword: [required(...['string', 'blur', '请输入旧密码'])],
                newPassword: [
                    required(...['string', 'blur', '请输入密码']),
                    range(8, 25),
                    password(),
                    {validator: validatePassword, trigger: 'blur'}
                ],
                confirmNewPassword: [
                    required(...['string', 'blur', '请再次输入密码']),
                    range(8, 25),
                    password(),
                    {
                        validator: validateConfirmPass,
                        trigger: 'blur'
                    }
                ],
            };
            return {
                form: {
                    oldPassword: null,
                    newPassword: null,
                    confirmPassword: null
                },
                modifying: false
            };
        },
        methods: {
            confirmModification() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        this.modifying = true;
                      /**等保修改**/
                       let arr = [];
                       arr.push(this.form.oldPassword),arr.push(this.form.newPassword);
                       this.form.oldPassword = MD5(this.form.oldPassword);
                       this.form.newPassword = MD5(this.form.newPassword);
                        const {confirmPassword, ...rest} = this.form;
                        changePassword(rest).then(_ => {
                            this.$emit('modified');
                            this.$msgSuccess('修改成功，请重新登录');
                        }).finally(() => setTimeout(() => {
                            this.form.oldPassword = arr[0];
                            this.form.newPassword = arr[1];
                            this.modifying = false
                        }, 2000));
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
