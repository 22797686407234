<template>
  <div @click="showQrCode" class="mar_t">
    <img :src="imgUrl" class="img_h_w" />
    <el-dialog
      :visible.sync="visible"
      destroy-on-close
      center
      append-to-body
      :before-close="qrCodeHandleClose"
      width="200px"
    >
      <div id="qrCode" ref="qrCode" class="qrCode_div"></div>
    </el-dialog>
  </div>
</template>
<script>
import { getTenantKey } from "@/util/auth";
import QRCode from "qrcodejs2";
import bjggs from "@/util/bjggs";
export default {
  name: "QrCode",
  components: {
    QRCode,
  },
  data() {
    return {
      visible: false,
      qrCode: "",
      imgUrl: require("@/assets/images/ewm.png"),
    };
  },
  methods: {
    showQrCode() {
      this.visible = true;
      this.qrcode();
    },
    //关闭弹框清除二维码
    qrCodeHandleClose() {
      this.visible = false;
      this.qrCode = "";
      document.getElementById("qrCode").innerHTML = "";
    },
    //创建二维码
    qrcode() {
      let _text = {
        tenantKey: getTenantKey(),
        baseApi: bjggs.BASE_API,
      };
      this.$nextTick(() => {
        this.qrCode = new QRCode("qrCode", {
          text: JSON.stringify(_text),
          width: 160,
          height: 160,
        });
      });
    },
  },
};
</script>
<style scoped>
 .mar_t {
    margin-top:6px ;
 }
 .img_h_w {
    width: 20px; 
    height: 20px;
 }
 .qrCode_div {
    width: 100%; 
    height: 175px;
 }
</style>
